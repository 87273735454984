import React, { useLayoutEffect } from 'react';
import Footer from '../../components/Footer';
import AboutMyWork from '../../components/AboutMyWork';
import Header from '../../components/Header';
import MenuHeader from '../../components/MenuHeader';
import PostHero from '../../components/PostHero';
import Topic from '../../components/Topic';
import DescriptionWithIcon from '../../components/DescriptionWithIcon';
import ChangeLanguage from '../../components/ChangeLanguage';
import WorkItem from '../../components/WorkItem';

import resume from '../../assets/cv/FlorenciaRodriguez-CV_EN.pdf';


const NucleoDesign = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  
  // eslint-disable-next-line no-multi-str
  const title = `DESIGN SYSTEMS \n NUCLEUS`
  const subtitle = 'Democratizing Design Systems:\nEmpowering a Multidisciplinary Design\nTeam'
  const problemTitle = "PROBLEM"
  const mainObjectives = "MAIN OBJECTIVES"
  const objectives = {
    1: 'Foster shared knowledge about Design Systems across all design disciplines.',
    2: 'Educate the team on the importance of Design Systems and their relevance beyond product design.',
    3: 'Establish team guidelines for working with Design Systems, including component naming, construction, library organization, and handoff to developers.',
    4: 'Facilitate collaboration and efficiency among different squads and projects.',
  }
  const description = "In today's dynamic and demanding market, agile and precise solutions are key to the success of digital products. Design Systems have emerged in recent years as a revolutionary approach to product development, utilizing reusable components akin to building blocks. By combining consistent design guidelines and shared code, Design Systems enable practical and cohesive solutions. With the aim of enhancing agility and precision as selling points, we focused on creating a Design System study group to disseminate knowledge within the design team. However, establishing this group came with challenges in taking the initial steps. The team was still structuring itself, comprising new members from diverse design backgrounds with different workflows, tools, and approaches. Overcoming these divergences and promoting standardized work and collaboration became an important challenge. Additionally, engaging the wider team beyond the product discipline posed difficulties, as not everyone understood the significance of a Design System beyond interface design or how to incorporate this logic into their own workflow."
  const execution = {
    title: 'EXECUTION',
    description: 'To address these challenges and achieve our objectives, we implemented the following initiatives: \n\n',
    paragraph1Title: 'Introductory Lecture: ',
    paragraph1: 'Collaborating with an enthusiastic colleague (Hi, Maycon!), we conducted an introductory lecture on Design Systems. This session aimed to demystify misconceptions and demonstrate the relevance of Design Systems in various domains, including technical, marketing, and human resources teams.\n\n',
    paragraph2Title: 'Knowledge Sharing Sessions: ',
    paragraph2: 'We invited team members to share their experiences and expertise beyond the traditional product context. We encouraged the participation of individuals from different companies to offer their perspectives. These sessions broadened our understanding of Design Systems as a robust ecosystem applicable to multiple disciplines.\n\n',
    paragraph3Title: 'Pills: ',
    paragraph3: 'We organized regular sessions called "Pills" in which we discussed selected articles or chapters about Design Systems. Through these discussions, we identified best practices, common opinions, and practical insights to develop a shared language and improve our working practices.\n\n',
    paragraph4Title: 'Emphasis on Collaboration: ',
    paragraph4: 'We highlighted the collaborative aspects of Design Systems, showcasing how disciplines such as visual design, content design, and illustration contribute to the system. We encouraged designers to view brand guidelines as an integral part of Design Systems, fostering collaboration among team members.'  
  }
const results = {
  title: 'RESULTS & FINAL THOUGHTS',
  description: 'Our efforts yielded positive achievements, including increased collaboration and improved project outcomes. Notable resolutions and results include:\n\n',
  paragraph1Title: 'File Matrix: ',
  paragraph1Description: 'We experimented with file matrices for project kickoff, organization, and labeling. This trial-and-error approach allowed us to adapt and find what works best for each project while maintaining a familiar central structure. This standardized approach increased collaboration and reduced divisions among different disciplines.\n\n',
  paragraph2Title: 'Increased Participation: ',
  paragraph2Description: 'Over time, team members became more open to participation and knowledge sharing. Attendance at sessions remained consistent, and team members began openly sharing relevant content found online through a shared "knowledge shelf."\n\n',
  paragraph3Title: 'Mentoring and Proactive discussions: ',
  paragraph3Description: 'With the sharing of information, affinities, and interests, the team started seeking internal information, multiplying the exchange of experiences, and seeking solutions in a more united and confident manner.\n\n',
  paragraph4: 'Throughout the project, my role as the design coordinator evolved. I became more attuned to the skills, strengths, and areas of development of team members. I will continue to encourage team members to actively contribute their own ideas and content to the shared team knowledge repository, while monitoring the quantity and quality of contributions to assess the impact of the initiative.'
}

  return (
    <div>
        <MenuHeader activeEN='active' toPT='/pt/nucleo-de-design-systems' />
        <Header colorChange={false} />
        <ChangeLanguage activeEN='active' toPT='/pt/nucleo-de-design-systems' device='Mobile' />
        <PostHero title={title} subtitle={subtitle} />
        <Topic>
          <h2>{problemTitle}</h2>
          <p>{description}</p>
        </Topic>
        <Topic>
          <h2>{mainObjectives}</h2>
          <div>
            <DescriptionWithIcon description={objectives[1] } />
            <DescriptionWithIcon description={objectives[2]} />
            <DescriptionWithIcon description={objectives[3]} />
            <DescriptionWithIcon description={objectives[4]} />
          </div>
        </Topic>
        <Topic>
          <h2>{execution.title}</h2>
          <p>
            {execution.description}
            <b>{execution.paragraph1Title}</b>{execution.paragraph1}
            <b>{execution.paragraph2Title}</b>{execution.paragraph2}
            <b>{execution.paragraph3Title}</b>{execution.paragraph3}
            <b>{execution.paragraph4Title}</b>{execution.paragraph4}
          </p>
        </Topic>
        <Topic>
          <h2>{results.title}</h2>
          <p>
            {results.description}
            <b>{results.paragraph1Title}</b>{results.paragraph1Description}
            <b>{results.paragraph2Title}</b>{results.paragraph2Description}
            <b>{results.paragraph3Title}</b>{results.paragraph3Description}
            {results.paragraph4}
          </p>
        </Topic>
        <AboutMyWork title='ABOUT MY WORK'>
          <WorkItem workTitle='Portfolio Mentorship' to='/en/portfolio-mentorship' workItem='Leadership' workItem2='Portfolio' />
          <WorkItem workTitle='Design System Nucleus' to='/en/design-systems-nucleus' workItem='Leadership' workItem2='Design Systems' />
        </AboutMyWork>
        <Footer title='Get in touch' emailTitle='Jobs or collaborations, send me an email' emailTitle2='to' resume={resume} downloadResume='Download my resume' copyright='2023. Developed by Matheus Coelho' />
    </div>
  );
}

export default NucleoDesign;