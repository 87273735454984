import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      dots: true,
      arrows: false,
      customPaging: function(i) {
        return (
          <a>
            {i + 1}
          </a>
        );
      },
      responsive: [
        {
          breakpoint: 768,
          settings:{
            customPaging: function(i) {
              return (
                <button></button>
              );
            }
          }
        }
      ]
    };
    
    return (
      <div className="projects-container">
        <Slider {...settings}>
          {this.props.children}
        </Slider>
      </div>
    );
  }
}
