import React from 'react';

class PostHero extends React.Component{
  render(){
    return( 
    <>
      <section className="post-hero">
        <div className="container">
          <h1>{this.props.title}</h1>
          <h2>{this.props.subtitle}</h2>
        </div>
      </section>
    </>
    )
  }
}

export default PostHero;