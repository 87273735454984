import React, { useLayoutEffect } from 'react';
import Footer from '../../components/Footer';
import AboutMyWork from '../../components/AboutMyWork';
import Header from '../../components/Header';
import MenuHeader from '../../components/MenuHeader';
import PostHero from '../../components/PostHero';
import Topic from '../../components/Topic';
import SimpleSlider from '../../components/Slider';
import SlideItem from '../../components/SlideItem';
import WorkItem from '../../components/WorkItem';
import ChangeLanguage from '../../components/ChangeLanguage';


import galleryImage from '../../assets/gallery-mentorship/mentorship-1.png';
import galleryImage2 from '../../assets/gallery-mentorship/mentorship-2.png';
import galleryImage3 from '../../assets/gallery-mentorship/mentorship-3.png';
import resume from '../../assets/cv/FlorenciaRodriguez-CV_PT.pdf';



const MentoriaPortfolioPT = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  // eslint-disable-next-line no-multi-str
  const title = `MENTORIA & \nPORTFOLIO`
  const subtitle = 'Criando um Circuito de Mentoria para\nCriação de Portfólio'
  const problemTitle = "PROBLEMA"
  const description = "Dentro da empresa, trabalhamos com vários formatos de venda, como escopo fechado, manutenção e venda individual de recursos para comporem times dentro do cliente, muitas vezes através de nossos parceiros. Escritórios estavam pedindo material dos talentos para avaliar fit técnico e cultural, mas muitas pessoas não tinham seu próprio portfolio, ou estava desatualizado, não refletindo o trabalho desenvolvido na WT. Como papel que atua no processo de recrutamento e avaliação de novas contratações, me senti apta para tomar frente deste processo. \n\nPara o projeto piloto apresentei a ideia para 2 dos meus liderados de produto que toparam participar da iniciativa. Optei por focar inicialmente em perfis de produto porque era uma área que eu me sentia mais confortável para opinar e liderar, visto que é a disciplina que tenho ênfase e assim, com essa experiência, poder estabelecer um plano principal e poder futuramente adaptar a outras áreas com ajuda de outros perfis técnicos, assim podendo personalizar melhor a experiência. Os talentos que me acompanharam nessa jornada eram dos meus liderados júniores, o que nos deu bastante amplitude para trabalhar.";
  const executionTitle = "EXECUÇÃO"
  const executionDescription = 'Para alcançar esse objetivo, montei um plano de ação estruturado, com encontros semanais descontraídos. Inicialmente, validei o interesse dos membros mais juniores da equipe nessa jornada, e a resposta foi muito positiva. Ao longo das semanas, compartilhamos referências de portfólios inspiradores, exemplos de cases e exploramos ferramentas acessíveis para atender às necessidades e aos orçamentos dos participantes. \n\nDurante os encontros semanais, cada participante apresentava seu progresso e recebia feedback. Esses encontros também proporcionaram debates interessantes sobre posicionamento e resultados, ajudando os participantes a aprimorar suas narrativas e a compreender a importância do autoconhecimento em sua jornada profissional. \n\nO piloto levou cerca de 6 meses, visto que era um projeto conduzido de forma proativa, dependendo da disponibilidade de agenda de todos os participantes. No entanto, durante esse período, tivemos várias discussões de bastante relevância para a área e para nosso desenvolvimento como time, e pude aprender bastante sobre o "lado B" dos portfolios, focando nas pessoas, e treinando meus critérios de avaliação como recrutadora.'
  const resultsTitle = "RESULTADOS & CONSIDERAÇÕES FINAIS"
  const resultsDescription = 'Participar desse processo de criação e atualização de portfólios foi uma experiência muito enriquecedora. Pude colaborar com o crescimento profissional e pessoal dos participantes, validando suas capacidades e ajudando-os a criar um material do qual se orgulham. Meu papel, além de mentoria e guiar os talentos nesse processo, foi de estímulo e apoio, incentivando-os a seguir em frente, mesmo quando enfrentavam momentos de frustração. \n\nUm dos debates mais interessantes foi a escolha da ferramenta ideal para a criação dos portfólios. Embora não exista uma ferramenta perfeita, concordamos que o foco estava na criação de uma narrativa forte e envolvente, independentemente da plataforma utilizada. Destacamos a importância de direcionar o material para a área de especialização desejada e de refletir sobre os aprendizados adquiridos em cada projeto.\n\nAcredito que essa experiência tenha ajudado os participantes a perceberem que eles sempre podem ir além e que tenham encontrado uma realização pessoal nessa jornada. Como coordenadora de design, meu objetivo foi contribuir com o crescimento de cada um e fornecer um ambiente acolhedor para o desenvolvimento de seus portfólios, e poder me aproximar de meus talentos para continuar a desenvolver uma jornada profissional mais assertiva com seus ideais.'
  return (
    <div>
        <MenuHeader activePT='active' toEN='/en/portfolio-mentorship' />
        <Header colorChange={false} />
        <ChangeLanguage activePT='active' toEN='/en/portfolio-mentorship' device='Mobile' />
        <PostHero title={title} subtitle={subtitle} />
        <Topic>
          <h2>{problemTitle}</h2>
          <p>{description}</p>
        </Topic>
        <Topic>
          <h2>{executionTitle}</h2>
          <p>{executionDescription}</p>
        </Topic>
        <section className="projects">
          <SimpleSlider>
            <SlideItem src={galleryImage} commingSoon={false} />
            <SlideItem src={galleryImage2} commingSoon={false} />
            <SlideItem src={galleryImage3} commingSoon={false} />
          </SimpleSlider>
        </section>
        <Topic>
          <h2>{resultsTitle}</h2>
          <p>{resultsDescription}</p>
        </Topic>
        <AboutMyWork title='SOBRE MEU TRABALHO'>
          <WorkItem workTitle='Mentoria e Portfolio' to='/pt/mentoria-e-portfolio' workItem='Liderança' workItem2='Portfolio' />
          <WorkItem workTitle='Núcleo de Design System' to='/pt/nucleo-de-design-systems' workItem='Liderança' workItem2='Design Systems' />
        </AboutMyWork>
        <Footer title='Entre em contato' emailTitle='Jobs ou colaborações, mande um email' emailTitle2='para' resume={resume} downloadResume='Baixe meu CV' copyright='2023. Codado por Matheus Coelho' />
    </div>
  );
}

export default MentoriaPortfolioPT;