import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

class Header extends React.Component{
  state = {
      firstItem:{
          color: '#1D1D1B'
      },
      secondItem:{
          color: '#1D1D1B'
      },
      thirdItem:{
          color: '#1D1D1B'
      },
      fourthItem:{
          color: '#1D1D1B'
      },
  }

  listenScrollEvent = e => {
    if (window.scrollY >= 0 && window.scrollY <= 849) {
      this.setState({firstItem: {color: '#1D1D1B'}})
    } else if (window.scrollY >= 850 && window.scrollY <= 1699) {
      this.setState({firstItem: {color: '#FFFFFF'}})
    } else if (window.scrollY >= 1700 && window.scrollY <= 2379) {
        this.setState({firstItem: {color: '#1D1D1B'}})
    } else if (window.scrollY >= 2940 && window.scrollY <= 3760) {
        this.setState({firstItem: {color: '#FFFFFF'}})
    } else if(window.scrollY >= 3761){
        this.setState({firstItem: {color: '#1D1D1B'}})
    }
    
    if (window.scrollY <= 796) {
        this.setState({secondItem: {color: '#1D1D1B'}})
      } else if (window.scrollY <= 1650) {
        this.setState({secondItem: {color: '#FFFFFF'}})
      } else if (window.scrollY <= 2890) {
          this.setState({secondItem: {color: '#1D1D1B'}})
      }
      else if (window.scrollY <= 3710) {
          this.setState({secondItem: {color: '#FFFFFF'}})
      } else{
          this.setState({secondItem: {color: '#1D1D1B'}})
      }

      if (window.scrollY <= 756) {
        this.setState({thirdItem: {color: '#1D1D1B'}})
      } else if (window.scrollY <= 1606) {
        this.setState({thirdItem: {color: '#FFFFFF'}})
      } else if (window.scrollY <= 2840) {
          this.setState({thirdItem: {color: '#1D1D1B'}})
      } else if (window.scrollY <= 3660) {
          this.setState({thirdItem: {color: '#FFFFFF'}})
      } else{
          this.setState({thirdItem: {color: '#1D1D1B'}})
      }

      if (window.scrollY <= 710) {
        this.setState({fourthItem: {color: '#1D1D1B'}})
      } else if (window.scrollY <= 1560) {
        this.setState({fourthItem: {color: '#FFFFFF'}})
      } else if (window.scrollY <= 2800) {
          this.setState({fourthItem: {color: '#1D1D1B'}})
      } else if (window.scrollY <= 3610) {
          this.setState({fourthItem: {color: '#FFFFFF'}})
      } else{
          this.setState({fourthItem: {color: '#1D1D1B'}})
      }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

    render(){
      return(     
        <header>
          <AnimatePresence>
            <motion.ul
            initial={{ opacity: 0, originY: 300 }}
            animate={{ opacity: 1, originY:1000 }}
            transition={{ delay: 0.2 }}>
              <li><a href="http://be.net/frodriguezpires" target="_blank" rel="noreferrer" style={this.props.colorChange ? {color: this.state.firstItem.color} : null}> Behance </a></li>
              <li><a href="https://dribbble.com/frodriguezpires" target="_blank" rel="noreferrer" style={this.props.colorChange ? {color: this.state.secondItem.color} : null}> Dribbble </a></li>
              <li><a href="https://www.instagram.com/frodriguezpires/" target="_blank" rel="noreferrer" style={this.props.colorChange ? {color: this.state.thirdItem.color} : null}> Instagram </a></li>
              <li><a href="https://www.linkedin.com/in/frodriguezpires/" target="_blank" rel="noreferrer" style={this.props.colorChange ? {color: this.state.fourthItem.color} : null}> LinkedIn </a></li>           
            </motion.ul>
          </AnimatePresence>   
        </header>
      )
    }
}

export default Header;