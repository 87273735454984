import React from 'react';

import logoHorizontal from '../assets/logo-horizontal.svg'

import { motion, AnimatePresence } from 'framer-motion';
import ChangeLanguage from './ChangeLanguage';
import { Link } from 'react-router-dom';

class MenuHeader extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      menu: {
        open: false,
      }
    }
  }

  toggleMenuHamburguer = () => {
    this.setState({menu: { open: !this.state.menu.open } })
  }

  render(){
    return( 
    <>
      <AnimatePresence>
        <motion.section className={`logo ${this.state.menu.open ? 'active' : ''}`}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y:0 }}
          transition={{ delay: 0.25 }}
          exit={{ opacity: 0 }}>
        <div className="menu-header">
            <Link to="/">
              <img src={logoHorizontal} alt="" />
            </Link>
            <div className="menu-burger" onClick={this.toggleMenuHamburguer.bind(this)}>
                <span className={`menu-burger-content ${this.state.menu.open ? 'active' : ''}`}></span>
            </div>
        </div>
        <AnimatePresence>
        {this.state.menu.open ?
          <motion.section className="mobile-header"
          initial={{ opacity: 0, y:0 }}
          animate={{ opacity: 1, y:0 }}
          transition={{ delay: 0 }}
          exit={{ opacity: 0}}>
            <ChangeLanguage 
              activeEN={this.props.activeEN}
              activePT={this.props.activePT} 
              toPT={this.props.toPT} 
              toEN={this.props.toEN} 
              device='Desktop' />
            <ul>
                <li><a href="http://be.net/frodriguezpires" target="_blank" rel="noreferrer"> Behance </a></li>
                <li><a href="https://dribbble.com/frodriguezpires" target="_blank" rel="noreferrer"> Dribbble </a></li>
                <li><a href="https://www.instagram.com/frodriguezpires/" target="_blank" rel="noreferrer"> Instagram </a></li>
                <li><a href="https://www.linkedin.com/in/frodriguezpires/" target="_blank" rel="noreferrer"> LinkedIn </a></li>
            </ul>
            <div className="contact">
                <a href="/"> frodriguezpires@gmail.com </a>
                <a href="/"> Download my resume </a>
            </div>
            <div className="coming-soon">
                FULL WEBSITE COMING SOON! / FULL WEBSITE COMING SOON!
            </div>
            </motion.section>
                : null
            }
        </AnimatePresence>
        </motion.section>
      </AnimatePresence>
    </>
    );
  };
}

export default MenuHeader;