import React, { useLayoutEffect } from 'react';

import Header from '../../components/Header'
import Slider from '../../components/Slider'
import Footer from '../../components/Footer'
import AboutMyWork from '../../components/AboutMyWork';
import MenuHeader from '../../components/MenuHeader';
import Hero from '../../components/Hero';
import About from '../../components/About';
import Experience from '../../components/Experience';
import Hobbies from '../../components/Hobbies';
import ChangeLanguage from '../../components/ChangeLanguage';
import SlideItem from '../../components/SlideItem';
import WorkItem from '../../components/WorkItem';

import galleryImage from '../../assets/gallery-image.png';
import galleryImage2 from '../../assets/gallery-image2.png';
import galleryImage3 from '../../assets/gallery-image3.png';
import resume from '../../assets/cv/FlorenciaRodriguez-CV_PT.pdf';


const HomePT = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div>
        <MenuHeader activePT='active' toEN='/'  />
        <Header colorChange={true} />
        <ChangeLanguage activePT='active' toEN='/' device='Mobile' />
        <Hero 
          title1='UI/visual designer morando em Pelotas'
          title2= 'E este é meu'
          workTitle='Trampo'
          aboutHer='Estudou Design Digital na Universidade Federal de Pelotas, com graduação sanduíche na OCAD University para publicidade e design gráfico, obtendo seu diploma de bacharel em 2016.' />
        <About 
          title='Nascida no verão brasileiro de ‘94, Flo (como é frequentemente chamada) se interessa pela teoria do design, solucionar problemas e compartilhar conhecimento.'
          title2='Organizada e detalhista, acredita no poder das linguagens de design como ferramentas de alinhamento e entendimento compartilhado no design de produtos.' />
        <Experience 
          title='Nos últimos 5 anos, atuou em várias disciplinas de design, incluindo identidade de marca, design editorial, publicidade e social media, antes de se especializar  em interface e interação.' 
          workingAt='Atualmente trabalha na'
          role='como coordenadora de Design.'
        />
        <AboutMyWork title='SOBRE MEU TRABALHO'>
          <WorkItem workTitle='Mentoria e Portfolio' to='/pt/mentoria-e-portfolio' workItem='Liderança' workItem2='Portfolio' />
          <WorkItem workTitle='Núcleo de Design System' to='/pt/nucleo-de-design-systems' workItem='Liderança' workItem2='Design Systems' />
        </AboutMyWork>
        <section className="projects">
            <Slider>
              <SlideItem src={galleryImage} commingSoon={true} />
              <SlideItem src={galleryImage2} commingSoon={true} />
              <SlideItem src={galleryImage3} commingSoon={true} />
            </Slider>
        </section>
        <Hobbies title='Seus hobbies incluem ouvir musica constantemente (em replay), comer quantias duvidosas de guacamole e afofar seu gato,'/>
        <Footer title='Entre em contato' emailTitle='Jobs ou colaborações, mande um email' emailTitle2='para' resume={resume} downloadResume='Baixe meu CV' copyright='2023. Codado por Matheus Coelho' />
    </div>
  );
}

export default HomePT;