import React from 'react';

class Topic extends React.Component {
  render() {
    return (
      <div className='container'> 
        <div className='topic'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Topic;