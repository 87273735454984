import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/en/home';
import MentoriaPortfolio from './pages/en/mentoria-portfolio';
import NucleoDesign from './pages/en/nucleos-design-systems';
import MentoriaPortfolioPT from './pages/pt/mentoria-portfolio';
import NucleoDesignPT from './pages/pt/nucleos-design-systems';
import HomePT from './pages/pt/home';

function App() {


  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/en/portfolio-mentorship" element={<MentoriaPortfolio />} />
          <Route path="/en/design-systems-nucleus" element={<NucleoDesign />} />
          <Route path="/pt" element={<HomePT />} />
          <Route path="/pt/mentoria-e-portfolio" element={<MentoriaPortfolioPT />} />
          <Route path="/pt/nucleo-de-design-systems" element={<NucleoDesignPT />} />
      </Routes>
    </div>
  );
}

export default App;
