import React from 'react';

class Experience extends React.Component{
  render(){
    return(
      <section className="experience">
      <div className="container">
          <h2>{this.props.title}</h2>
          <h2> {this.props.workingAt} <a href="https://www.linkedin.com/company/wunderman-thompson-technology-br/" target="_blank" rel="noreferrer">Wunderman Thompson Tech Brazil</a> {this.props.role} </h2>    
      </div>
      </section>
    )
  }
}

export default Experience;