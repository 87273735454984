import React from 'react';

import sunIcon from '../assets/sunshine-icon.png';


class DescriptionWithIcon extends React.Component {
  render() {
    return (
      <div className="description-with-icon">
        <img src={sunIcon} alt="icon" />
        <p>{this.props.description}</p>
      </div>
    );
  }
}

export default DescriptionWithIcon;