import React from 'react';
import { Link } from 'react-router-dom';

class WorkItem extends React.Component{
  render() {
    return(
      <Link to={this.props.to} className="work">
        <div className='work-title'>
          <div className='arrow'></div>
          <h4> {this.props.workTitle} </h4>
        </div>
        <div className='skills'>
            <span> {this.props.workItem} </span>
            <span> {this.props.workItem2} </span>
        </div>
      </Link>
    )
  }
}

export default WorkItem;