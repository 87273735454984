import React from 'react';

class Footer extends React.Component{
  render() {
    return(
      <footer>
          <div className="container">
              <div className="footer__contact-us">
                  <h3> 
                      {this.props.title}
                  </h3>
                  <h3>
                      {this.props.emailTitle} <br className="hideMobile"/>
                      {this.props.emailTitle2} <a href="mailto:frodriguezpires@gmail.com"> frodriguezpires@gmail.com </a>
                  </h3>
                  <h3>
                      <i></i>
                      <a href={this.props.resume} target="_blank" rel="noreferrer"> {this.props.downloadResume} </a>
                  </h3>
              </div>
              <hr />
              <div className="copyrights">
                  <p>{this.props.copyright}</p>
              </div>
          </div>
      </footer>
    )
  }
}

export default Footer;