import React from "react";

class SlideItem extends React.Component {
  render() {

    return (
      <div className="slide-item">
        <img src={this.props.src} alt="Projeto 1" />
        {this.props.commingSoon &&
          <div className="comming-soon"> 
            <span className="comming-soon-text"> COMI<span className="comming-soon-n">N</span>G SOON </span>
          </div>
        }
      </div>
    );
  }
}

export default SlideItem;