import React, { useLayoutEffect } from 'react';
import Footer from '../../components/Footer';
import AboutMyWork from '../../components/AboutMyWork';
import Header from '../../components/Header';
import MenuHeader from '../../components/MenuHeader';
import PostHero from '../../components/PostHero';
import Topic from '../../components/Topic';
import SimpleSlider from '../../components/Slider';
import SlideItem from '../../components/SlideItem';

import galleryImage from '../../assets/gallery-mentorship/mentorship-1.png';
import galleryImage2 from '../../assets/gallery-mentorship/mentorship-2.png';
import galleryImage3 from '../../assets/gallery-mentorship/mentorship-3.png';
import ChangeLanguage from '../../components/ChangeLanguage';
import WorkItem from '../../components/WorkItem';

import resume from '../../assets/cv/FlorenciaRodriguez-CV_EN.pdf';

const MentoriaPortfolio = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  // eslint-disable-next-line no-multi-str
  const title = `PORTFOLIO \n MENTORSHIP`
  const subtitle = 'Designing a Mentoship Circuit for Portfolio\nDevelopment'
  const problemTitle = "PROBLEM"
  const description = "Within the company, we work with various sales formats, such as closed scope, maintenance and individual sale of resources to compose teams within the client, often through our partners. Firms were asking for talent material to assess technical and cultural fit, but many people didn't have their own portfolio, or it was outdated, not reflecting the work done at WT. As a role that acts in the process of recruiting and evaluating new hires, I felt able to take charge of this process. \n\nFor the pilot project, I presented the idea to 2 of my product leaders who agreed to participate in the initiative. I chose to focus initially on product profiles because it was an area that I felt more comfortable giving my opinion and leading, since it is the discipline that I emphasize and thus, with this experience, being able to establish a main plan and be able to adapt it to other areas in the future with the help of other technical profiles, thus being able to better customize the experience. The talents that accompanied me on this journey came from my junior team members, which gave us a lot of scope to work with.";
  const executionTitle = "EXECUTION"
  const executionDescription = 'To achieve this goal, I put together a structured action plan, with relaxed weekly meetings. Initially, I validated the interest of the more junior team members in this journey, and the response was very positive. Over the weeks, we shared inspirational portfolio references, case examples, and explored accessible tools to meet attendees needs and budgets. \n\nDuring the weekly meetings, each participant presented their progress and received feedback. These meetings also provided interesting debates on positioning and results, helping participants to improve their narratives and understand the importance of self-knowledge in their professional journey. \n\nThe pilot took about 6 months, as it was a proactively conducted project, depending on the schedule availability of all participants. However, during this period, we had several very relevant discussions for the area and for our development as a team, and I was able to learn a lot about the "B side" of portfolios, focusing on people, and training my evaluation criteria as a recruiter.'
  const resultsTitle = "RESULTS & FINAL THOUGHTS"
  const resultsDescription = 'Participating in this process of creating and updating portfolios was a very enriching experience. I was able to collaborate with the professional and personal growth of the participants, validating their abilities and helping them to create material that they are proud of. My role, in addition to mentoring and guiding the talents in this process, was one of encouragement and support, encouraging them to move forward, even when they faced moments of frustration. \n\nOne of the most interesting debates was the choice of the ideal tool for creating portfolios. While there is no perfect tool, we agreed that the focus was on creating a strong and engaging narrative, regardless of the platform used. We emphasize the importance of directing the material to the desired area of ​​specialization and of reflecting on the learning acquired in each project.\n\nI believe this experience helped the participants to realize that they can always go further and that they found personal fulfillment in this journey. As a design coordinator, my goal was to contribute to the growth of each one and provide a welcoming environment for the development of their portfolios, and to be able to approach my talents to continue to develop a more assertive professional journey with their ideals.'
  return (
    <div>
        <MenuHeader activeEN='active' toPT='/pt/mentoria-e-portfolio'  />
        <Header colorChange={false} />
        <ChangeLanguage activeEN='active' toPT='/pt/mentoria-e-portfolio' device='Mobile' />
        <PostHero title={title} subtitle={subtitle} />
        <Topic>
          <h2>{problemTitle}</h2>
          <p>{description}</p>
        </Topic>
        <Topic>
          <h2>{executionTitle}</h2>
          <p>{executionDescription}</p>
        </Topic>
        <section className="projects">
          <SimpleSlider>
            <SlideItem src={galleryImage} commingSoon={false} />
            <SlideItem src={galleryImage2} commingSoon={false} />
            <SlideItem src={galleryImage3} commingSoon={false} />
          </SimpleSlider>
        </section>
        <Topic>
          <h2>{resultsTitle}</h2>
          <p>{resultsDescription}</p>
        </Topic>
        <AboutMyWork title='ABOUT MY WORK'>
          <WorkItem workTitle='Portfolio Mentorship' to='/en/portfolio-mentorship' workItem='Leadership' workItem2='Portfolio' />
          <WorkItem workTitle='Design System Nucleus' to='/en/design-systems-nucleus' workItem='Leadership' workItem2='Design Systems' />
        </AboutMyWork>
        <Footer title='Get in touch' emailTitle='Jobs or collaborations, send me an email' emailTitle2='to' resume={resume} downloadResume='Download my resume' copyright='2023. Developed by Matheus Coelho' />
    </div>
  );
}

export default MentoriaPortfolio;