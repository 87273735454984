import React from 'react';
import { Link } from 'react-router-dom';

class ChangeLanguage extends React.Component{
  render(){
    return(
      <div className={`container hide${this.props.device}`}>
        <div className="change-language">
          <Link to={this.props.toEN} className={this.props.activeEN}>EN</Link>
          <Link to={this.props.toPT} className={this.props.activePT}>PT</Link>
        </div>
      </div>
    )
  }
}

export default ChangeLanguage;