import React, { useLayoutEffect } from 'react';

import Header from '../../components/Header'
import Slider from '../../components/Slider'
import Footer from '../../components/Footer'
import AboutMyWork from '../../components/AboutMyWork';
import MenuHeader from '../../components/MenuHeader';
import Hero from '../../components/Hero';
import About from '../../components/About';
import Experience from '../../components/Experience';
import Hobbies from '../../components/Hobbies';
import ChangeLanguage from '../../components/ChangeLanguage';
import SlideItem from '../../components/SlideItem';
import WorkItem from '../../components/WorkItem';

import galleryImage from '../../assets/gallery-image.png';
import galleryImage2 from '../../assets/gallery-image2.png';
import galleryImage3 from '../../assets/gallery-image3.png';
import resume from '../../assets/cv/FlorenciaRodriguez-CV_EN.pdf';


const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div>
        <MenuHeader activeEN='active' toPT='/pt' />
        <Header colorChange={true} />
        <ChangeLanguage activeEN='active' toPT='/pt' device='Mobile' />
        <Hero 
          title1='Brazilian-Uruguayan UI/visual designer based in Pelotas'
          title2= 'and this is my'
          workTitle='Work'
          aboutHer='She studied Digital Design at the Federal University of Pelotas with a one-year exchange experience at OCAD University for advertising and graphic design, earning her bachelor’s degree in 2016.' />
        <About 
          title='Born in the south-american summer of ’94, Flo (as she’s oftenly called) is interested in design theory, problem solving and constantly sharing knowledge.'
          title2='Organized and detail-oriented, she believes in the power of design languages as tools for alignment and shared understanding in product design.' />
        <Experience 
          title='In the last 5 years she’s played across many design disciplines, including brand identity, editorial design, advertising and social media before mainly focusing in interface and interaction.' 
          workingAt='Currently working at'
          role='as Design Coordinator.'
        />
        <AboutMyWork title='ABOUT MY WORK'>
          <WorkItem workTitle='Portfolio Mentorship' to='/en/portfolio-mentorship' workItem='Leadership' workItem2='Portfolio' />
          <WorkItem workTitle='Design System Nucleus' to='/en/design-systems-nucleus' workItem='Leadership' workItem2='Design Systems' />
        </AboutMyWork>
        <section className="projects">
            <Slider>
              <SlideItem src={galleryImage} commingSoon={true} />
              <SlideItem src={galleryImage2} commingSoon={true} />
              <SlideItem src={galleryImage3} commingSoon={true} />
            </Slider>
        </section>
        <Hobbies title='Hobbies include constantly listening to music (mostly on replay), eating copious amounts of guacamole and snuggling with her cat,'/>
        <Footer title='Get in touch' emailTitle='Jobs or collaborations, send me an email' emailTitle2='to' resume={resume} downloadResume='Download my resume' copyright='2023. Developed by Matheus Coelho' />
    </div>
  );
}

export default Home;