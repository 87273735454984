import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../assets/elipse.json';
import animationData2 from '../assets/seta_loop.json';


class Hero extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      animationState: {
        isStopped: false, isPaused: false
      }
    }
  }

  render(){
    const defaultOptions = {
      loop: false,
      autoplay: false, 
      animationData: animationData,
      rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const defaultOptions2 = {
      loop: true,
      autoplay: false, 
      animationData: animationData2,
      rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
      }
    };

    setTimeout(() => { this.setState({isStopped: false, isPaused: false })}, 1000);

    return(     
    <section className="hero">
        <div className="container">
          <AnimatePresence>
            <motion.div className="blur" 
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 1 }}
            />
          </AnimatePresence>
          <AnimatePresence>
            <motion.div className="arrowLottie hideMobile"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.75 }}
            >
                <Lottie
                    options={defaultOptions2}
                    height={100}
                    width={40}
                    isStopped={this.state.animationState.isStopped}
                    isPaused={this.state.animationState.isPaused}
                /> 
            </motion.div> 
          </AnimatePresence>
          <AnimatePresence>
            <motion.div className="arrowLottie hideDesktop"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.75 }}
            >
                <Lottie
                    options={defaultOptions2}
                    height={60}
                    width={24}
                    isStopped={this.state.animationState.isStopped}
                    isPaused={this.state.animationState.isPaused}
                /> 
              </motion.div> 
          </AnimatePresence>
          <AnimatePresence>
            <motion.h1
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.75 }}
                >
                {this.props.title1}
            </motion.h1>
          </AnimatePresence>
          <AnimatePresence>
            <motion.span className="workTitle"
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.75 }}
            >
              {this.props.title2} <div className="workLottie hideMobile"> 
              <Lottie 
                  options={defaultOptions}
                  height={124}
                  width={382}
                  isStopped={this.state.animationState.isStopped}
                  isPaused={this.state.animationState.isPaused} 
              /> </div>
              <div className="workLottie hideDesktop"> 
              <Lottie
                  options={defaultOptions}
                  height={92}
                  width={284}
                  isStopped={this.state.animationState.isStopped}
                  isPaused={this.state.animationState.isPaused} 
              /> </div>
              <span> {this.props.workTitle} </span>
            </motion.span>
          </AnimatePresence>
          <h2>
            {this.props.aboutHer}
          </h2>
        </div>
      </section>
    )
  }
}

export default Hero;