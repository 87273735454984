import React from 'react';

import aboutImage from '../assets/about-image.png'

class About extends React.Component{
  render(){
    return(
      <section className="about">
      <div className="container">
          <div className="about__description">
              <h2>{this.props.title}</h2>
              <h2>{this.props.title2}</h2>
          </div>
          <div className="about__image">
              <img src={aboutImage} alt="" />
          </div>
      </div>
      </section>
    )
  }
};

export default About;