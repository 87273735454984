import React from 'react';

class AboutMyWork extends React.Component{
  render() {
    return(
      <section className="about-my-work">
      <div className="container">
          <h2> {this.props.title} </h2>
          <div className="works">
            {this.props.children}
          </div>
        </div>
      </section>
    )
  }
}

export default AboutMyWork;