import React, { useLayoutEffect } from 'react';
import Footer from '../../components/Footer';
import AboutMyWork from '../../components/AboutMyWork';
import Header from '../../components/Header';
import MenuHeader from '../../components/MenuHeader';
import PostHero from '../../components/PostHero';
import Topic from '../../components/Topic';
import DescriptionWithIcon from '../../components/DescriptionWithIcon';
import ChangeLanguage from '../../components/ChangeLanguage';
import WorkItem from '../../components/WorkItem';
import resume from '../../assets/cv/FlorenciaRodriguez-CV_PT.pdf';


const NucleoDesignPT = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  // eslint-disable-next-line no-multi-str
  const title = `NÚCLEOS DE DESIGN \nSYSTEMS`
  const subtitle = 'Democratizando Design Systems:\nEmpoderando uma Equipe de Design \nMultidisciplinar'
  const problemTitle = "PROBLEMA"
  const description = 'O mercado, cada vez mais dinâmico e exigente, demanda soluções ágeis e precisas, e para o sucesso de um produto digital soluções práticas e consistentes são chaves. Nos últimos anos, os Design Systems entraram em cena e ganharam destaque para revolucionar a forma como desenvolvemos produtos, através de componentes reutilizáveis (como peças de lego), aliando diretrizes de design consistentes e código compartilhado. Visando agilidade e precisão como recurso de venda, focamos na criação de um núcleo de estudos de Design System para multiplicar o conhecimento dentro do time de design. No entanto, ao estabelecer esse grupo, encontramos alguns desafios para dar os primeiros passos: o time como um todo estava se estruturando, com diversos novos membros da equipe com conhecimento em design systems de diferentes origens, com fluxos de trabalho, ferramentas e abordagens distintas. Superar essa divergência e promover a padronização do trabalho e a colaboração era um desafio importante. O segundo desafio visava engajar o grande grupo além da disciplina de produto, pois nem todos da equipe compreendiam a importância de um Design System para além do design de interfaces e tinham dificuldade em compreender como incorporar essa lógica em seu fluxo de trabalho.'
  const mainObjectives = "OBJETIVOS PRINCIPAIS"
  const objectives = {
    1: 'Estimular o conhecimento comum sobre Design Systems em todas as disciplinas de design.',
    2: 'Educar a equipe sobre a importância dos Design Systems e sua relevância além do design de produtos.',
    3: 'Estabelecer diretrizes de equipe para trabalhar com Design Systems, incluindo nomenclatura de componentes, construção, organização de bibliotecas e repasse para desenvolvedores.',
    4: 'Facilitar a colaboração e eficiência entre diferentes squads e projetos.',
  }
  const execution = {
    title: 'EXECUÇÃO',
    description: 'Para lidar com esses desafios e atingir nossos objetivos, implementamos as seguintes iniciativas: \n\n',
    paragraph1Title: 'Palestra Introdutória: ',
    paragraph1: 'Em colaboração com um colega entusiasmado (Oi, Maycon!), realizamos uma palestra introdutória sobre Design Systems. Essa sessão teve como objetivo desmistificar concepções errôneas e demonstrar a relevância dos Design Systems em diferentes domínios, incluindo equipes técnicas, de marketing e de recursos humanos.\n\n',
    paragraph2Title: 'Sessões de Compartilhamento de Conhecimento: ',
    paragraph2: 'Convidamos membros da equipe a compartilharem suas experiências e conhecimentos além do tradicional contexto de produtos. Incentivamos a participação de pessoas de diferentes empresas para compartilhar suas perspectivas. Essas sessões ampliaram nossa compreensão dos Design Systems como um ecossistema robusto aplicável a várias disciplinas.\n\n',
    paragraph3Title: 'Pílulas: ',
    paragraph3: 'Organizamos sessões regulares chamadas "Pílulas", nas quais discutimos artigos ou capítulos selecionados sobre Design Systems. Por meio dessas discussões, identificamos as melhores práticas, opiniões comuns e insights práticos para desenvolver uma linguagem compartilhada e aprimorar nossas práticas de trabalho.\n\n',
    paragraph4Title: 'Ênfase na Colaboração: ',
    paragraph4: 'Destacamos os aspectos colaborativos dos Design Systems, mostrando como disciplinas como design visual, design de conteúdo e ilustração contribuem para o sistema. Incentivamos os designers a enxergarem os manuais de identidade como parte integrante dos Design Systems, promovendo a colaboração entre os membros da equipe.'  
  }
  
  const results = {
    title: 'RESULTADOS & CONSIDERAÇÕES FINAIS',
    description: 'Nossos esforços resultaram em conquistas positivas, incluindo uma maior colaboração e melhores resultados de projetos. Algumas resoluções e resultados notáveis incluem:\n\n',
    paragraph1Title: 'Matrizes de Arquivos: ',
    paragraph1Description: 'Experimentamos matrizes de arquivos para o início de projetos, organização e rotulagem. Essa abordagem de tentativa e erro permitiu-nos adaptar e encontrar o que funciona melhor para cada projeto, mantendo uma estrutura central familiar. Essa abordagem padronizada aumentou a colaboração e reduziu as divisões entre diferentes disciplinas.\n\n',
    paragraph2Title: 'Aumento na Participação: ',
    paragraph2Description: 'Ao longo do tempo, os membros da equipe tornaram-se mais abertos à participação e ao compartilhamento de conhecimento. A presença nas sessões manteve-se consistente, e os membros da equipe começaram a compartilhar abertamente conteúdos relevantes encontrados na internet por meio de uma "estante de conhecimento" compartilhada."\n\n',
    paragraph3Title: 'Mentorias e Trocas Pro-ativas: ',
    paragraph3Description: 'Com o compartilhamento de informação, afinidades e interesses, o time passou a procurar informações internamente, multiplicando as trocas de experiência e buscando soluções de forma mais unida e confiante.\n\n',
    paragraph4: 'Ao longo do projeto, minha função como coordenadora de design evoluiu. Tornei-me mais sintonizada com as habilidades, pontos fortes e áreas de desenvolvimento dos membros da equipe. Continuarei a incentivar os membros da equipe a contribuírem ativamente com suas próprias ideias e conteúdos para o repositório compartilhado de conhecimento da equipe e monitorando a quantidade e qualidade das contribuições para avaliar o impacto da iniciativa.'
  }

  return (
    <div>
        <MenuHeader activePT='active' toEN='/en/design-systems-nucleus' />
        <Header colorChange={false} />
        <ChangeLanguage activePT='active' toEN='/en/design-systems-nucleus' device='Mobile' />
        <PostHero title={title} subtitle={subtitle} />
        <Topic>
          <h2>{problemTitle}</h2>
          <p>{description}</p>
        </Topic>
        <Topic>
          <h2>{mainObjectives}</h2>
          <div>
            <DescriptionWithIcon description={objectives[1] } />
            <DescriptionWithIcon description={objectives[2]} />
            <DescriptionWithIcon description={objectives[3]} />
            <DescriptionWithIcon description={objectives[4]} />
          </div>
        </Topic>
        <Topic>
          <h2>{execution.title}</h2>
          <p>
            {execution.description}
            <b>{execution.paragraph1Title}</b>{execution.paragraph1}
            <b>{execution.paragraph2Title}</b>{execution.paragraph2}
            <b>{execution.paragraph3Title}</b>{execution.paragraph3}
            <b>{execution.paragraph4Title}</b>{execution.paragraph4}
          </p>
        </Topic>
        <Topic>
          <h2>{results.title}</h2>
          <p>
            {results.description}
            <b>{results.paragraph1Title}</b>{results.paragraph1Description}
            <b>{results.paragraph2Title}</b>{results.paragraph2Description}
            <b>{results.paragraph3Title}</b>{results.paragraph3Description}
            {results.paragraph4}
          </p>
        </Topic>
        <AboutMyWork title='SOBRE MEU TRABALHO'>
          <WorkItem workTitle='Mentoria e Portfolio' to='/pt/mentoria-e-portfolio' workItem='Liderança' workItem2='Portfolio' />
          <WorkItem workTitle='Núcleo de Design System' to='/pt/nucleo-de-design-systems' workItem='Liderança' workItem2='Design Systems' />
        </AboutMyWork>
        <Footer title='Entre em contato' emailTitle='Jobs ou colaborações, mande um email' emailTitle2='para' resume={resume} downloadResume='Baixe meu CV' copyright='2023. Codado por Matheus Coelho' />
    </div>
  );
}

export default NucleoDesignPT;