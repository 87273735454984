import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';

import citrusImage from '../assets/citrus-image.png'


class Hobbies extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      hover: false
    }
  }

  mouseOver = () => {
    this.setState({hover:true})
  }

  mouseOut = () => {
    this.setState({hover: false})
  }

  render(){
    return(
      <section className="hobbies">
        <div className="container">
          <h3>
              {this.props.title} 
              <span onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)} > Citrus.</span>
          </h3>
          <div className="hobbies-img-container hideMobile">
            <AnimatePresence>
                {this.state.hover ? 
                ( <motion.img
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    src={citrusImage} 
                    alt="Citrus" />  ) 
                : null
                }       
            </AnimatePresence>
          </div>
        </div>
      </section>
    )
  }
}

export default Hobbies;